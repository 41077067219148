<template>
  <th-page-wrapper>
    <th-datatable
      ref="table"
      :headers="headers"
      do-route
      do-route-filters
      show-search-filter
      :show-operations="false"
      no-meta-check
      route-base="/inventory/reasons"
      resource="reasons"
      :resource-query="{ query: { deleted: false } }"
      :buttons="computedButtons"
      @loading-error="handleLoadingError"
    />
  </th-page-wrapper>
</template>

<script>
import safeGet from 'just-safe-get'

export default {
  metaInfo() {
    return {
      title: this.$t('nav.main.items.inventory.reasons')
    }
  },
  data() {
    return {
      headers: [
        {
          field: 'name',
          label: this.$t('common.headers.name.title'),
          fallback: '-',
          truncate: true
        },
        {
          field: 'description',
          label: this.$t('common.headers.description.title'),
          fallback: '-',
          truncate: true
        },
        {
          field: 'type',
          label: this.$t('common.headers.type.title'),
          fallback: '-',
          width: 150,
          truncate: true,
          formatter: (row) => {
            const type = safeGet(row, 'type')
            return type ? this.typesTranslations[type] : '-'
          }
        }
      ],
      selectedItems: [],
      buttons: [
        {
          type: 'create',
          scopes: ['inventory:reasons:create']
        }
      ]
    }
  },
  computed: {
    isEdit() {
      return this.$route.name === 'reasons-edit'
    },
    isNew() {
      return this.$route.name === 'reasons-new'
    },
    typesTranslations() {
      return {
        refund: this.$t(
          'pages.reasons.edit.form.properties.types.refund.label'
        ),
        stock_change: this.$t(
          'pages.reasons.edit.form.properties.types.stock_change.label'
        ),
        expense: this.$t(
          'pages.reasons.edit.form.properties.types.expense.label'
        ),
        deposit: this.$t(
          'pages.reasons.edit.form.properties.types.deposit.label'
        ),
        pos_price_change: this.$t(
          'pages.reasons.edit.form.properties.types.pos_price_change.label'
        )
      }
    },
    computedButtons() {
      return this.buttons.filter((b) =>
        b.scopes ? this.$checkPermissions({ scopes: b.scopes }) : true
      )
    }
  },
  methods: {
    handleClose() {
      this.$router.push({ name: 'reasons-list' })
    },
    refresh() {
      this.handleClose()
      this.$refs.table.refresh()
    },
    handleLoadingError(err) {
      this.$logException(err, {
        trackError: false,
        message: this.$t('common.error.action.read.multiple', {
          resources: this.$t('pages.reasons.title')
        })
      })
    }
  }
}
</script>

<style scoped></style>
